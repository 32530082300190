
header {
    @import "~base/variables";
    @import "bootstrap/scss/mixins/breakpoints";
    
    $breakpoint-name: 'sm';
    $breakpoint-name: 'sm' !default;
    $breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
    $prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
    $next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);
    
    $slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    
    /** FC MEGAMENU **/

    @keyframes moveToRight {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    @keyframes moveToLeft {
        0% {
            left: 0%;
        }
        100% {
            left: -100%;
        }
    }

    .menu-toggler {
        display: none;
        order: 1;
    }
    // button:nth-child(1) {
    //     order: 2
    // }
    .main-menu {
        display: none;
    }


    @media screen and (max-width: 1023.98px) {
        .menu-toggler {
            &::before {
                left: inherit;
            }
            display: block;
        }

        .main-menu {
            position: fixed;
            background: white;
            height: 100vh;
            overflow: auto;
            top: 0;
            left: -100%;
            z-index: 999;
            min-width: 320px;
            max-width: 500px;
            width: 100%;
            animation: moveToLeft .3s ease-in-out;
            &.show {
                display: block;
            }
            &.opened {
                left: 0;
                animation: moveToRight .3s ease-in-out;
            }

            .fc-navbar {
                padding: 0;
                .close-menu {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 35px 20px;
                    background: #f5f2ea;
                    // button {
                    //     color: white;
                    // }
                }
                .fc-wrapper-navbar-nav {
                    overflow-x: auto;
                    background: #f5f2ea;
                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                    scrollbar-width: none;  /* Firefox */

                    &::-webkit-scrollbar { 
                        display: none;  /* Safari and Chrome */
                    }
                }
                .fc-navbar-nav {
                    display: flex;
                    list-style: none;
                    justify-content: flex-start;
                    font-size: 20px;
                    // color: white;
                    flex-wrap: nowrap;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    flex-direction: row;
                    padding: 0 20px;
                    width: max-content;
                    margin: 0 auto;

                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                    scrollbar-width: none;  /* Firefox */

                    &::-webkit-scrollbar { 
                        display: none;  /* Safari and Chrome */
                    }

                }
                .fc-nav-item {
                    flex-shrink: 0;
                    margin: 0 10px;
                    padding-bottom: 10px;
                    font-size: 15px;
                    border-bottom: 5px solid transparent;
                    text-transform: capitalize;

                    &.active {
                        border-bottom: 5px solid #fff;
                    }

                    &>a.fc-nav-link {
                        font-weight: 700;
                    }
                    .fc-megamenu-container {
                        display: none;
                    }
                }

                .fc-megamenu-row {
                    .fc-megamenu-column {
                        &:first-child {
                            & > li {
                                &:first-child {
                                    padding-top: 30px;
                                }
                            }
                        }
                        & > li {
                            padding-bottom: 20px;
                            display: block;
                        }
                        &:last-child {
                            & > li {
                                &:last-child {                            
                                    padding-bottom: 30px;
                                }
                            }
                        }
                        & > .fc-category-second-level > .fc-menu-item-link {
                            &> a {
                                font-weight: bold !important;
                                text-transform: uppercase;
                                padding: 0 30px;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                    
                        }
                    }
                    .fc-nav-item {
                        margin: 0;
                        a {
                            padding: 0 30px;
                        }
                    }
                }
            }

        }
    }
    
    @media screen and (min-width: 1024px) {
        .main-menu {
            display: block;
            width: 100%;
        }
    
        .fc-navbar-nav {
            display: flex;
            align-items: center;
            margin: 0;
            justify-content: center;

            & >.fc-nav-item {
                padding: 6px 0;
                margin: 0 10px;
                height: 100%;
                border-bottom: 5px solid transparent;
            }
    
            .fc-nav-item {
    
                .fc-nav-link {
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:not(.show) {
                    .fc-megamenu-container {
                        display: none;
                    }
                }
                &.show {
                    border-bottom: 5px solid;
    
                    .fc-megamenu-container {
                        padding-left: 2%;
                        padding-right: 2%;
                        background: #fff;
                        position: absolute;
                        right: 0;
                        width: 100%;
                        height: auto;
                        overflow-y: auto;
                        z-index: 9;
                        padding: 20px 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        left: 0;
                        background: white;
                        top: 40px;
                        max-height: 500px;
                        box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
    
                        .fc-megamenu-row {
                            display: flex;
                            position: static;
                            right: 0;
                            width: 100%;
                            height: auto;
                            font-size: 15px;
                            flex-wrap: wrap;
                            justify-content: center;
    
                            .fc-megamenu-column {
                                padding-left: 0;
                                list-style: none;
                                width: 16.666666666666667%;
                                padding-bottom: 0;
                            }
                            .fc-category-second-level {
                                padding: 0 3%;
                                padding-bottom: 60px;

                                &:last-child {
                                    padding-bottom: 0;
                                }
    
                                .fc-menu-item-link {
                                    width: 90%;
                                    padding-bottom: 6px;
                                    border-bottom: 1px solid #dadada;
                                    &>a {
                                        color: #001526;
                                        font-size: 16px;
                                        padding-left: 0;
                                        padding-right: 0;
                                        display: flex;
                                        align-items: center;
                                        font-weight: 700;
                                    }
                                }
    
                                .fc-menu-item-list {
                                    list-style: none;
                                    padding: 1rem 0;
                                    padding-top: 18px;
                                    font-weight: 400;
                                    flex-direction: column;
                                    flex-wrap: wrap;

                                    .fc-nav-item{
                                        a {
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
}




